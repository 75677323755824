import React from 'react';
import { Helmet } from 'react-helmet';
import {
  Typography,
  Box,
  Container,
  Section,
  Layout,
  Grid,
  Button,
  Image,
  Link,
} from '../components';
import styled from 'styled-components';
import ServiceHeroSection from '../components/ServiceHeroSection';
import bannerImage from '../images/services/mlSolution/mLSolutionBanner.svg';
import dataAnalystImg from '../images/services/mlSolution/dataAnalysis.svg';
import generativeAi from '../images/services/mlSolution/generativeAI.svg';
import machineLearning from '../images/services/mlSolution/machineLearning.svg';
import { motion, AnimatePresence } from 'framer-motion';
import Flex from './../components/Flex';
import { stats, technologies } from '../config/data';

const ServiceContentImage = styled.img`
  box-shadow: 25px 25px 0px 0px var(--secondary-color);
  margin: 25px 0;
  width: 100%;

  @media screen and (max-width: 768px) {
    box-shadow: 10px 10px 0px 0px var(--secondary-color);
  }
`;
const MotionBox = motion(
  styled.div`
    border: 1px solid #152539;
    cursor: pointer;
    background-color: var(--tech-bg);
    height: 130px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  `
);
const ValueBox = styled(Box)`
  background-color: #061324;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  margin: 10px;
  height: 150px;

  @media (max-width: 768px) {
    padding: 15px;
    margin: 5px;
  }
`;
const techBoxVariants = {
  initial: { opacity: 0, y: 20 },
  hover: { scale: 1.1 },
  enter: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: -20 },
};
const GridContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;
const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 16px;
  flex-direction: column;

  @media (min-width: 600px) {
    flex-direction: row;
  }
`;
const aiMlSolution = () => {
  return (
    <Layout title="AI/ML Solution">
      <Helmet>
        <meta
          name="description"
          content="Spring Rain's AI & ML services drive automation, customer engagement, and advanced analytics for sustainable growth. Data analysis, generative AI, and machine learning solutions tailored to your needs."
        />
      </Helmet>
      <Container mt={{ xs: 8, md: 12 }}>
        <ServiceHeroSection
          title={'AI/ML Solution'}
          imageSrc={bannerImage}
          imgAlt={'AI/ML Solution'}
        />
        <Section>
          <Typography my={5} variant="paragraph1">
            Spring Rain's AI & ML services and solutions empower organizations
            to process automation, strengthen customer engagement, and leverage
            advanced analytics for long-term sustainability and growth.
          </Typography>

          <Grid
            gap="150px"
            gapSm="15px"
            alignItems="center"
            gridTemplateColumns={{ _: '1fr', sm: '3fr 3fr' }}
            mb={3}
          >
            <Box>
              <ServiceContentImage src={dataAnalystImg} />
            </Box>
            <Box>
              <Typography mt={2} variant="heading5">
                Data Analysis
              </Typography>

              <Typography mt={2} variant="paragraph1">
                We help identify trends, predict customer behavior, and optimize
                marketing campaigns by harnessing AI's advanced capability so
                you can find new opportunities to grow.
              </Typography>
            </Box>
          </Grid>

          <Grid
            gap="150px"
            gapSm="15px"
            alignItems="center"
            gridTemplateColumns={{ _: '1fr', sm: '3fr 3fr' }}
            mb={3}
          >
            <Box flip={true}>
              <Typography mt={2} variant="heading5">
                Generative AI
              </Typography>

              <Typography mt={2} variant="paragraph1">
                We enhance your creative processes, accelerate data synthesis,
                and get personalized content generation, such as images, text,
                or videos, tailored to your target audience.
              </Typography>
            </Box>
            <Box>
              <ServiceContentImage src={generativeAi} />
            </Box>
          </Grid>

          <Grid
            gap="150px"
            gapSm="15px"
            alignItems="center"
            gridTemplateColumns={{ _: '1fr', sm: '3fr 3fr' }}
            mb={3}
          >
            <Box>
              <ServiceContentImage src={machineLearning} />
            </Box>
            <Box>
              <Typography mt={2} variant="heading5">
                Machine Learning and Deep Learning
              </Typography>

              <Typography mt={2} variant="paragraph1">
                We build and train models that can learn from data, identify
                patterns, and make decisions with minimal human intervention.
              </Typography>
            </Box>
          </Grid>
        </Section>
        <Section>
          <Grid gridTemplateColumns={{ _: '1fr', sm: '1fr' }}>
            <Box>
              <Typography
                style={{ textAlign: 'center' }}
                variant="heading3"
                mb={3}
              >
                How Experienced We Are
              </Typography>
            </Box>
          </Grid>
          <Grid
            gridTemplateColumns={{
              _: '1fr',
              sm: '1fr 1fr',
              md: '1fr 1fr 1fr 1fr',
            }}
          >
            {stats.map(stat => (
              <ValueBox key={stat.label}>
                <Typography variant="heading3">{stat.value}</Typography>
                <Typography variant="paragraph1">{stat.label}</Typography>
              </ValueBox>
            ))}
          </Grid>
        </Section>
        <Section>
          <Grid
            gridTemplateColumns={{ _: '1fr', sm: '1fr' }}
            justifyItems="center"
          >
            <Box>
              <Typography
                style={{ textAlign: 'center' }}
                variant="heading3"
                mb={3}
              >
                Technologies We Have Expertise In
              </Typography>
            </Box>
          </Grid>
          <Flex>
            <AnimatePresence>
              {technologies
                .filter(tech => tech.category === 'ai-ml')
                .map((tech, index) => (
                  <div key={index}>
                    <MotionBox
                      initial="initial"
                      animate="enter"
                      exit="exit"
                      whileHover="hover"
                      variants={techBoxVariants}
                      transition={{ duration: 0.5 }}
                    >
                      <Image width="100px" src={tech.src} alt={tech.alt} />
                    </MotionBox>
                  </div>
                ))}
            </AnimatePresence>
          </Flex>
        </Section>
        <Section>
          <Grid gridTemplateColumns={{ _: '1fr', sm: '1fr' }}>
            <Box>
              <Typography
                style={{ textAlign: 'center' }}
                variant="heading3"
                mb={3}
              >
                Ready To Build Your Brand?
              </Typography>
            </Box>
          </Grid>
          <GridContainer>
            <ButtonContainer>
              <Link to="/contact/">
                <Button variant="contained" color="primary" primary>
                  Contact Us
                </Button>
              </Link>
              <Link to="/expertise/">
                <Button variant="contained" color="primary">
                  View All Services
                </Button>
              </Link>
            </ButtonContainer>
          </GridContainer>
        </Section>
      </Container>
    </Layout>
  );
};

export default aiMlSolution;
